import React from 'react';
import SpecialOffer from "./SpecialOffer";
import styles from './Prices.module.css'; // Import CSS module

const Prices = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Ціни</h1>
      <div className={styles.upperDiv}>
        <p>Вартість абонементів для катання <span className={styles.orangeText}>на&nbsp;кільцевій&nbsp;дорозі</span></p>
      </div>
      <div className={styles.columnsContainer}>
        <div className={styles.column1}>
          <h2 style={{visibility: 'hidden'}} className={styles.subtitle} >ДЛЯ ДІТЕЙ ДО 16 РОКІВ</h2>
          <p className={styles.tableText}  >30 ХВИЛИН</p>
          <p className={styles.tableText}  >1 ГОДИНА</p>
          <p className={styles.tableText} >2 ГОДИНИ</p>
          <p className={styles.tableText}  >ДЕННА КАРТА</p>
          <p className={styles.tableText}>10-ДЕННА КАРТА</p>
        </div>
      <div className={styles.column2}>
        <h2 className={styles.subtitle}>ДЛЯ ДІТЕЙ ДО 16 РОКІВ</h2>
        <p className={styles.tableText} >300</p>
        <p className={styles.tableText} >550</p>
        <p className={styles.tableText}  >850</p>
        <p className={styles.tableText}  >1050</p>
        <p className={styles.tableText} >7800</p>
      </div>
      <div className={styles.column3}>
        <h2 className={styles.subtitle}>ДЛЯ ДОРОСЛИХ</h2>
        <p  className={styles.tableText} >400</p>
        <p  className={styles.tableText} >700</p>
        <p className={styles.tableText}  >1050</p>
        <p className={styles.tableText}  >1300</p>
        <p className={styles.tableText} >9750</p>
      </div>
      </div>
      <div className={styles.underDiv}>
        <p>*Жилет , шолом, лижі, ніборд та диск входять до вартості абонемента на катання</p>
        <p>Послуги тренера 800 грн на годину</p>
      </div>
      <div className={styles.upperDivLeft}>
        <p>Прокат інвентарю</p>
      </div>
      <div className={styles.columnsContainer}>
        <div className={styles.column1}>
          <h2 className={styles.subtitle}>ІНВЕНТАР</h2>
          <p className={styles.tableText}>ВЕЙКБОРД УЧБОВИЙ</p>
          <p className={styles.tableText}>ВЕЙКБОРД ПРОФЕСІЙНИЙ</p>
          <p className={styles.tableText}>ВЕЙКБОРД ДЛЯ СТРИБКІВ</p>
          <p className={styles.tableText}>ВЕЙКСКЕЙТ</p>
          <p className={styles.tableText}>ГІДРОКОСТЮМ</p>
        </div>
        <div className={styles.column2}>
          <h2 className={styles.subtitle}>На 1 ГОДИНУ</h2>
          <p className={styles.tableText}>200</p>
          <p className={styles.tableText}>250</p>
          <p className={styles.tableText}>300</p>
          <p className={styles.tableText}>200</p>
          <p className={styles.tableText}>150</p>
        </div>
        <div className={styles.column3}>
          <h2 className={styles.subtitle}>НА ДЕНЬ</h2>
          <p className={styles.tableText}>250</p>
          <p className={styles.tableText}>300</p>
          <p className={styles.tableText}>350</p>
          <p className={styles.tableText}>200</p>
          <p className={styles.tableText}>250</p>
        </div>
      </div>
      <SpecialOffer />
    </div>
  );
};

export default Prices;
