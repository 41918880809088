import React, { useState } from 'react';
import styles from './Header.module.css'; // Import CSS module

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className={styles.header}>
      <img
        src="/background.webp"
        alt="Background Image"
        className={styles.backgroundImage}
      />
      <div className={styles.contentContainer}>
        <p className={styles.heading}>Ласкаво просимо до</p>
        <p style={{color: '#FFFFFF'}} className={styles.heading}>SENTOSA Cable Park</p>
      </div>
      {/*MENU*/}
      {/*<div className={`${styles.menuContainer} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}>*/}
      {/*  /!* Mobile Menu Icon *!/*/}
      {/*  <div className={styles.mobileMenuIcon} onClick={toggleMobileMenu}>*/}
      {/*    <div className={styles.bar}></div>*/}
      {/*    <div className={styles.bar}></div>*/}
      {/*    <div className={styles.bar}></div>*/}
      {/*  </div>*/}
      {/*  /!* Menu Items *!/*/}
      {/*  <nav className={styles.menuItems}>*/}
      {/*    <a href="#">Menu Item 1</a>*/}
      {/*    <a href="#">Menu Item 2</a>*/}
      {/*    <a href="#">Menu Item 3</a>*/}
      {/*    <a href="#">Menu Item 4</a>*/}
      {/*  </nav>*/}
      {/*</div>*/}
    </header>
  );
};

export default Header;
