import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  const openGoogleMaps = () => {
    const formattedAddress = encodeURIComponent("Вейк-парк Сентоса, вулиця Набережна Перемоги, 37Б, Дніпро, Дніпропетровська область, Украина, 49000");
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
    window.open(googleMapsUrl, '_blank');
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.logo}>
        <img src="/logo.webp" alt="Logo" />
      </div>
      <div className={styles.contactDetails}>
        <p className={styles.phone}>
          <a href="tel:+380501335050" className={styles.phoneLink}>
            +38 050 133 50 50
          </a>
        </p>
        {/*<p className={styles.email}>*/}
        {/*  <a href="mailto:info@example.com" className={styles.emailLink}>*/}
        {/*    info@example.com*/}
        {/*  </a>*/}
        {/*</p>*/}
      </div>
      <div style={{maxWidth: "120px", cursor: "pointer"}} className={styles.contactDetails} onClick={openGoogleMaps}>
        <p>вулиця Набережна Перемоги 37Б, Дніпро</p>
      </div>
      <div className={styles.social}>
        {/* Social media buttons */}
        <a href="https://www.instagram.com/sentosa_cable_park/" target="_blank" rel="noopener noreferrer">
          <img
            className={styles.socialIcon}
            alt="Instagram"
            src="Instagram.png"
          />
        </a>
        <a href="https://www.facebook.com/sentosacablepark/" target="_blank" rel="noopener noreferrer">
          <img
            className={styles.socialIcon}
            alt="Facebook"
            src="Facebook.png"
          />
        </a>
        <a href="https://www.tiktok.com/@sentosa_cable_park/" target="_blank" rel="noopener noreferrer">
          <img
            className={styles.socialIcon}
            alt="TikTok"
            src="TikTok.png"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
