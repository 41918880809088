import React from "react";
import styles from "./OpenHours.module.css";

const OpenHours = () => {
  return (
    <div className={styles.componentContainer}>
      <h1 className={styles.title}>Розклад</h1>
      <div className={styles.columnsContainer}>
        <div className={styles.column}>
          <h2>Понеділок-П'ятниця:</h2>
          <p style={{margin: '5px'}}>07:00-19:00 Реверсивні дороги</p>
          <p style={{margin: '5px'}}>15:00-19:00 Кільцева дорога</p>
          <h2>Субота-Неділя:</h2>
          <p style={{margin: '5px'}}>07:00-19:00 Реверсивні дороги</p>
          <p style={{margin: '5px'}}>13:00-19:00 Кільцева дорога</p>
        </div>
        <div className={styles.column}>
          <h2>Запис здійснюється за наступним номером телефона:</h2>
          <p className={styles.phone}>
            <a href="tel:+380501335050" className={styles.phoneLink}>
              +38 050 133 50 50
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OpenHours;
