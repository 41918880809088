import React from 'react';
import styles from './Trainings.module.css';

const Trainings = () => {
  return (
    <div className={styles.componentContainer}>
      <h1 className={styles.title}>Тренування</h1>
      <div className={styles.smallBox}>
        <p>Ми забезпечимо Вам всі необхідні засоби безпеки та високоякісні обладнання для зручності і комфорту. Будьте готові до захоплюючих моментів та приголомшливих трюків!</p>
      </div>
      <div className={styles.columnsContainer}>
        <div className={styles.column}>
          <h2 style={{color: '#EA6122'}}>Експрес-тренування</h2>
          <p className={styles.subText}>Для початківців та просунутих райдерів. Допоможе швидко стати на дошку чи освоїти нові трюки</p>
          <img src="/trainings1.svg" alt="Image 1" />
        </div>
        <div className={styles.column}>
          <h2 style={{color: '#EA6122'}}>Тренування на кільці</h2>
          <p className={styles.subText}>Для сучасних райдерів. Заняття з персональним тренером допоможе швидко освоїти фігури</p>
          <img src="/trainings2.svg" alt="Image 2" />
        </div>
        <div className={styles.column}>
          <h2 style={{color: '#EA6122'}}>Групові тренування</h2>
          <p className={styles.subText}>Катання для початківців та просунутих райдерів</p>
          <img src="/trainings3.svg" alt="Image 3" />
        </div>
      </div>
    </div>
  )
};

export default Trainings;
