import React, { useEffect, useRef } from 'react';
import styles from './Championat.module.css';

const Championat = () => {

  const isMobile = window.screen.width < 900;

  const openForm = () => {
    window.open('https://forms.gle/BbjUJP8ANzSYWuHV6', '_blank');
  }

  const handleDownload = () => {
    const pdfUrl = '/Bulletin.pdf'; // Replace with the actual path to your PDF file
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'Bulletin.pdf'; // Set the desired file name for download
    link.click();
  };

  const handleOpen = () => {
    const pdfUrl = '/Bulletin.pdf'; // Replace with the actual path to your PDF file
    window.open(pdfUrl, '_blank');
  };

  return (
  <div className={styles.contentContainer}>
    <h1 className={styles.title}>Відкритий Чемпіонат України з кабельного вейкбордингу у місті Дніпро (у жовтні)</h1>
    <div className={styles.subDescription}>
      <p>Запрошуємо всіх любителів екстремальних відчуттів та активного відпочинку взяти участь у чемпіонаті з кабельного вейкбордингу, який відбудеться в жовтні у місті Дніпро.</p>
    </div>
    <div className={styles.subDescriptionSmall}>
      <p>Ці змагання – це не лише можливість виявити себе у конкурентному середовищі, а й чудова нагода провести час активно та з користю для здоров'я. Не пропустіть можливість покращити свої навички, зустріти однодумців та насолодитися захоплюючою атмосферою змагань.</p>
      <p onClick={handleOpen}><span className={styles.orangeText}>Бюлетень змагань (завантажити).</span></p>
    </div>
    <div style={{fontWeight: 600}} className={styles.subDescriptionSmall}>
      <p>Реєструйтеся вже сьогодні та готуйтеся до незабутніх вражень!</p>
    </div>
    <div className={styles.subDescription}>
      <button onClick={openForm} className={styles.buttonSignUp}>Зареєструватися</button>
    </div>
  </div>
  )
};

export default Championat;
