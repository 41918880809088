import React from 'react';
import styles from './SpecialOffer.module.css';

const SpecialOffer = () => {
  return (
      <div className={styles.subcomponentContainer}>
        <div className={styles.header}>
          <p>СПЕЦІАЛЬНА ПРОПОЗИЦІЯ ПО ПОНЕДІЛКАХ - ЗНИЖКА 30%</p>
          <span>На весь сезон</span>
        </div>
        <div className={styles.columnsContainer}>
          <div className={styles.column}>
            <h2 style={{visibility: 'hidden'}} className={styles.subtitle}>1 ГОДИНA</h2>
            <p className={styles.tableText}>Для дітей</p>
            <p className={styles.tableText}>Для дорослих</p>
          </div>
          <div className={styles.column}>
            <h2 className={styles.subtitle}>1 ГОДИНA</h2>
            <p className={styles.tableText}>350</p>
            <p className={styles.tableText}>500</p>
          </div>
          <div className={styles.column}>
            <h2 className={styles.subtitle}>2 ГОДИНИ</h2>
            <p className={styles.tableText}>600</p>
            <p className={styles.tableText}>750</p>
          </div>
          <div className={styles.column}>
            <h2 className={styles.subtitle}>1 ДЕНЬ</h2>
            <p className={styles.tableText}>750</p>
            <p className={styles.tableText}>900</p>
          </div>
        </div>
        <div className={styles.text}>
          <p className={styles.textSubTitle}>ПРОПОЗИЦІЯ ДЛЯ ОРГАНІЗОВАНИХ ГРУП</p>
          <p className={styles.textSubText}>Ви можете замовити послуги для дозвілля з вашою спортивною секцією, клубом,
            шкільним класом, студентською групою, колегами по роботі, друзями або відсвяткувати значну подію у вашому
            житті.</p>
          <p className={styles.textSubText}>Замовте годину катання, до якої входять послуги досвідченого інструктора,
            необхідний інвентар (лижі, шолом, жилет, ніборд).</p>
          <p className={styles.textSubNote}>*Для групових катань здійснюється попередній запис за телефоном <a
              href="tel:+380501335050" className={styles.phoneLink}>
            +38&nbsp;050&nbsp;133&nbsp;50&nbsp;50
          </a></p>
        </div>
        <div className={styles.columnsContainer}>
          <div className={styles.columnPartOf3}>
            <h2 className={styles.subtitlePartOf3}>Оренда кільцевої дороги</h2>
            <p className={styles.tableText}>На 1 годину</p>
          </div>
          <div className={styles.columnPartOf3}>
            <h2 className={styles.subtitlePartOf3}>Понеділок – п’ятниця з&nbsp;7.00&nbsp;до&nbsp;12.00</h2>
            <p className={styles.tableText}>4000</p>
          </div>
          <div className={styles.columnPartOf3}>
            <h2 className={styles.subtitlePartOf3}>Субота, неділя, святкові дні з&nbsp;8.00&nbsp;до&nbsp;12.00</h2>
            <p className={styles.tableText}>5000</p>
          </div>
        </div>
        <div className={styles.columnsContainer}>
          <div className={styles.columnPartOf3}>
            <h2 className={styles.subtitlePartOf3}>Оренда реверсивної дороги</h2>
            <p className={styles.tableText}>Понеділок – п’ятниця</p>
            <p className={styles.tableText}>Субота - неділя</p>
          </div>
          <div className={styles.columnPartOf3}>
            <h2 className={styles.subtitlePartOf3}>30 хвилин</h2>
            <p className={styles.tableText}>600</p>
            <p className={styles.tableText}>700</p>
          </div>
          <div className={styles.columnPartOf3}>
            <h2 className={styles.subtitlePartOf3}>1 година</h2>
            <p className={styles.tableText}>1200</p>
            <p className={styles.tableText}>1400</p>
          </div>
        </div>
      </div>
  );
};

export default SpecialOffer;
