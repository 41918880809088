import React, { useEffect, useRef } from 'react';
import styles from './DescriptionMain.module.css';

const DescritionMain = () => {

  const sourceDivRef = useRef(null);
  const targetDivRef = useRef(null);
  const sourceDivRef1 = useRef(null);
  const targetDivRef1 = useRef(null);
  const isMobile = window.screen.width < 900

  useEffect(() => {
    const setTargetDivHeight = () => {
      const sourceDivHeight = sourceDivRef.current.offsetHeight;
      targetDivRef.current.style.height = `${sourceDivHeight}px`;
      const sourceDivHeight1 = sourceDivRef1.current.offsetHeight;
      targetDivRef1.current.style.height = `${sourceDivHeight1}px`;
    };
    !isMobile && setTargetDivHeight();
    !isMobile && window.addEventListener('resize', setTargetDivHeight);
    return () => {
      !isMobile && window.removeEventListener('resize', setTargetDivHeight);
    };
  }, []);


  return (
  <div className={styles.contentContainer}>
    <div className={styles.descriptionAndImage}>
      {!isMobile && (<div ref={targetDivRef} id="image1" className={styles.imageContent}>
        <img style={{height: '100%'}} src="/sentosa-park.webp" alt="Image" />
      </div>)}
      <div ref={sourceDivRef} id="text1" className={styles.textContent}>
        <p>
          В 2013 році у місті Дніпро відкрився перший сучасний парк водних розваг світового&nbsp;рівня SENTOSA.
        </p>
        <p>
          Парк водних розваг SENTOSA <span className={styles.orangeText}>побудований&nbsp;за міжнародними стандартами канатних кільцевих доріг німецькою&nbsp;компанією&nbsp;Rixen.</span>
        </p>
        <p>
          Довжина дороги складає <span className={styles.orangeText}>більше&nbsp;800&nbsp;метрів</span> та одночасно на ній можуть кататися <span className={styles.orangeText}>12&nbsp;людей.</span> На маршруті встановлено <span className={styles.orangeText}>8&nbsp;фігур,</span> щоб досвідчені вейкбордисти могли виповнювати свої трюки.
        </p>
      </div>
    </div>
    <div className={styles.descriptionAndImage}>
      <div ref={sourceDivRef1} id="text2" className={styles.textContent}>
        <p>
          Якщо ви <span className={styles.orangeText}>новачок</span> і тільки починаєте свою пригоду з цим захоплюючим видом спорту, наші досвідчені інструктори з радістю допоможуть вам освоїти основи техніки та нададуть вам необхідну підтримку.
        </p>
        <p>
          Для більш <span className={styles.orangeText}>досвідчених та впевнених спортсменів</span> ми пропонуємо водні лижі з більш складними трасами та перешкодами. Ви зможете насолоджуватись швидкістю та адреналіном, роблячи захоплюючі трюки та переконуючись у своїх вміннях.
        </p>
      </div>
      {!isMobile && (<div ref={targetDivRef1} id="image2" className={styles.imageContent}>
        <img style={{height: '100%', objectPosition: '0% 0%'}} src="/guys_1.webp" alt="Image" />
      </div>)}
    </div>
    <div className={styles.subDescription}>
      <p>Якщо ви шукаєте захоплюючу діяльність, яка забезпечить вам драйв, веселощі та незабутні враження, ви знайшли саме те місце, де ваші бажання стануть реальністю!</p>
    </div>
  </div>
  )
};

export default DescritionMain;
